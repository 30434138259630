import { GeneralTexts, OtherTexts } from "../language/Texts"
import { EmailBodyType } from "../models/EmailBodyType.enum"
import { IGeneralAttributes } from "../models/IGeneralAttributes"
import { Utils } from "./Utils"

export const getMailBody = (generalAttributeList: IGeneralAttributes, type: EmailBodyType, articleDescriptionS?: string): string => {
    let text = ''
    const componentType = generalAttributeList.componentType
    const serialNumber = generalAttributeList.serialNumber
    const batchNumber = generalAttributeList.batchNumber
    const articleNumberExt = generalAttributeList.articleNumberExt
    const orderNumber = generalAttributeList.orderNumber

    if (articleDescriptionS) {
        text += `${articleDescriptionS}\n`
    }

    if (articleNumberExt) {
        text += `${GeneralTexts.type}: ${articleNumberExt.trim()}\n`
    }

    text += OtherTexts.linkToWebView + window.location.toString() + '\n'

    // Rembe Teile-Nr
    if (componentType) {
        text += `${GeneralTexts.articleDesignation}: ${componentType}\n`
    }

    // Chargen-Nr
    if (batchNumber) {
        text += `${GeneralTexts.batchNumber}: ${batchNumber}\n`
    }

    if (serialNumber && type === EmailBodyType.REORDER) {
        text += `${GeneralTexts.serialNumber}: ${serialNumber}\n`
    }

    if (orderNumber) {
        text += `${GeneralTexts.orderConfirmation}: ${Utils.tryParseDate(orderNumber)}\n`
    }

    return text.replace(/\n/g, '%0D%0A')
}


export const openEmailSender = (mailSubject: string, mailBody: string, hasNotMailto?: boolean) => {
    let mailConfig = `mailto:hello@rembe.de?subject=${mailSubject}&body=${mailBody}`
    if (hasNotMailto) {
        mailConfig = `mailto:?subject=${mailSubject}&body=${mailBody}`
    }
    window.open(mailConfig)
}