import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Utils } from "../utils/Utils";
import translation from './translation.json';

const lang = Utils.getBrowserLanguage();

let resources = {};
Object.keys(translation.language).forEach((key) => {
    resources[key] = { translation: translation }
})

i18n.use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        lng: lang,
        resources: resources
    })

if (!Utils.isLanguageSupported(lang)) {
    i18n.changeLanguage('en');
}

export default i18n