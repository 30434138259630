import './SectionTitle.scss';

interface SectionTitleProps {
    title: string
    icon: any
}

function SectionTitle({ title, icon }: SectionTitleProps) {
    return (
        <div className='section_title_wrapper' >
            <span className='section_title_icon' style={{ color: "#009a93" }}>{icon}</span>
            <span className='section_title' >{title}</span>
        </div>
    )
}

export default SectionTitle
