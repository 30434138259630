import { GeneralTexts } from '../../language/Texts';
import { CommonComponentProps } from '../../models/CommonComponentProps';
import { EmailBodyType } from '../../models/EmailBodyType.enum';
import { getMailBody, openEmailSender } from '../../utils/EmailSender';
import './Contact.scss';

function Contact({ articleDescriptionS, generalAttributeList }: CommonComponentProps) {

    let mailSubject = `${GeneralTexts.request}: ${articleDescriptionS} - ${GeneralTexts.digitalTagPlate}`
    if (generalAttributeList.componentType) {
        mailSubject = `${GeneralTexts.request}: ${articleDescriptionS} - ${GeneralTexts.digitalTagPlate} | ${generalAttributeList.componentType}`
    }
    const mailBody = getMailBody(generalAttributeList, EmailBodyType.CONTACT, articleDescriptionS)

    return (
        <div className='contact_wrapper'>
            <div className="contact_text_wrapper">
                <div className="contact_title">{GeneralTexts.connectWithUs}</div>
                <div className="contact_text">REMBE® GmbH Safety+Control</div>
                <div className="contact_text">Gallbergweg 21</div>
                <div className="contact_text">59929 Brilon, Deutschland</div>
                <div className="contact_text">T +49 2961 7405-0</div>
                <div className="contact_text mail_text"
                    data-testid="contact_mail"
                    onClick={() => openEmailSender(mailSubject, mailBody)}
                >hello@rembe.de</div>
            </div>
        </div>
    )
}

export default Contact
