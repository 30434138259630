import infoIcon from '../../assets/infoIcon.png';
import { GeneralTexts } from '../../language/Texts';
import { EmailBodyType } from '../../models/EmailBodyType.enum';
import { IGeneralAttributes } from '../../models/IGeneralAttributes';
import { getMailBody, openEmailSender } from '../../utils/EmailSender';
import './InfoButton.scss';

export interface ContactProps {
    articleDescriptionS: string;
    generalAttributeList: IGeneralAttributes;
}

function InfoButton({ articleDescriptionS, generalAttributeList }: ContactProps) {

    let mailSubject = `${GeneralTexts.request}: ${articleDescriptionS} - ${GeneralTexts.digitalTagPlate}`
    if (generalAttributeList.articleNumberExt) {
        mailSubject = `${GeneralTexts.request}: ${articleDescriptionS} - ${GeneralTexts.digitalTagPlate} | ${generalAttributeList.articleNumberExt}`
    }
    const mailBody = getMailBody(generalAttributeList, EmailBodyType.CONTACT, articleDescriptionS)
    return (
        <div className="info_button_wrapper">
            <img
                data-testid="info_button"
                onClick={() => openEmailSender(mailSubject, mailBody)}
                className="info_button" src={infoIcon} />
        </div>
    )
}

export default InfoButton
