import { Accordion } from 'react-bootstrap';
import './AccordionComponent.scss';

interface AccordionComponentProps {
    title: JSX.Element,
    body: JSX.Element,
    eventKey: string
}
function AccordionComponent({ title, body, eventKey }: AccordionComponentProps) {
    return (
        <Accordion defaultActiveKey="2" className='accordion_wrapper'>
            <Accordion.Item eventKey={"1"} >
                <Accordion.Header className='accordion_header'>{title}</Accordion.Header>
                <Accordion.Body>
                    {body}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default AccordionComponent
