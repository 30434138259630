import IResponse from '../models/IResponse';
import version from '../version.json';

export class HttpRequests {
    async requestComponent(lang?: string): Promise<IResponse | null> {
        const requestURL = this.getRequestUrl('getcomponent');
        const requestData = this.getRequestData(lang);

        try {
            const response = await fetch(requestURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const result = await this.sendResponseWithVersion(response);
            return result;
        } catch (error) {
            console.error('Error on getting component', error);
            throw error;
        }
    }

    private getRequestUrl(path: string): string {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // return `http://192.168.14.11:8080/api/${path}`;
            return `http://localhost:8080/api/${path}`;
        } else {
            return `/api/${path}`;
        }
    }

    private async sendResponseWithVersion(response: Response): Promise<IResponse> {
        const versionHeader = response.headers.get('Webview-Backend-Version') ?? null;
        const responseData = await response.json();

        if (versionHeader) {
            responseData.backendVersion = versionHeader;
        }

        return responseData;
    }

    private getRequestData = (lang?: string) => {
        const requestData = {
            url: window.location.href,
            language: lang,
            type: 'rembe',
            additional: {
                frontendVersion: version.version,
                displayResolution: `${window.screen.width}x${window.screen.height}`,
            },
        };
        return requestData;
    };
}
