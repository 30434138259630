import CertificateComponent from '../../components/certificateComponent/CertificateComponent';
import ComponentAtrributes from '../../components/componentAttributes/ComponentAtrributes';
import Contact from '../../components/contact/Contact';
import DocumentsOnDelievery from '../../components/documentsOnDelievery/DocumentsOnDelievery';
import Footer from '../../components/footer/Footer';
import General from '../../components/general/General';
import Header from '../../components/header/Header';
import InfoButton from '../../components/infoButton/InfoButton';
import ProductAttributes from '../../components/productAttributes/ProductAttributes';
import ProductDocuments from '../../components/productDocuments/ProductDocuments';
import ProductSnapshot from '../../components/productSnapshot/ProductSnapshot';
import { IOutputData } from '../../models/IOutputData';
import './Main.scss';

interface MainProps {
    component: IOutputData,
    language: string,
    setLanguage: (lang: string) => void
}

function Main({ component, language, setLanguage }: MainProps) {

    return (
        <div className='main_wrapper' data-testid="main" >
            <div className="first_page_wrapper">
                <Header
                    language={language}
                    setLanguage={setLanguage}
                />
                <ProductSnapshot
                    component={component}
                />
            </div>

            <General
                articleDescriptionS={component.articleDescriptionS}
                generalAttributeList={component.generalAttributesList} />
            <ProductAttributes attributes={component.productAttributesList} />
            <ComponentAtrributes attributes={component.manufacturerAttributesList} />
            <CertificateComponent attributes={component.productAttributesListPrioZero} />
            <ProductDocuments documentList={component.productDocuments} />
            <DocumentsOnDelievery documentList={component.componentDocuments} />
            <Contact
                articleDescriptionS={component.articleDescriptionS}
                generalAttributeList={component.generalAttributesList} />
            <Footer />
            <InfoButton
                articleDescriptionS={component.articleDescriptionS}
                generalAttributeList={component.generalAttributesList} />

        </div>
    )
}

export default Main
