import truckIcon from '../../assets/truckIcon.png';
import { GeneralTexts } from '../../language/Texts';
import { IDocument } from '../../models/IDocument';
import AccordionComponent from '../accordionComponent/AccordionComponent';
import SectionTitle from '../sectionTitle/SectionTitle';

interface DocumentProps {
    documentList: IDocument[]
}

function DocumentsOnDelievery({ documentList }: DocumentProps) {
    const componentBody =
        <div className="document_list">
            {documentList?.map((document, index) => {
                return (
                    <a
                        key={index}
                        className="doc_link"
                        href={document.link}
                        rel="noreferrer"
                        type={document.mimeType}
                        target="_blank"
                        data-testid="document_link"
                    >
                        {document.title}
                    </a>
                )
            })
            }
        </div>

    const titleComponent =
        <SectionTitle
            icon={<img className="section_title_icon" src={truckIcon} />}
            title={GeneralTexts.documentationUponDelivery} />

    return (
        <>
            {documentList.length > 0 &&
                <AccordionComponent
                    title={titleComponent}
                    body={componentBody}
                    eventKey="-1"
                />}
        </>
    )
}

export default DocumentsOnDelievery
