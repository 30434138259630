import { Dropdown } from 'react-bootstrap';
import i18n from '../../language/i18n';
import translation from '../../language/translation.json';
import './LanguageSelector.scss';

function LanguageSelector({ language, setLanguage }) {

    const languages = translation['language'];
    const languageCodes = Object.keys(languages)
    const languageNames = Object.values(languages)

    const handleChangeLanguage = (eventKey) => {
        setLanguage(languages[eventKey])
        i18n.changeLanguage(eventKey);
    };

    return (
        <Dropdown
            onSelect={handleChangeLanguage}
            className='language_dropdown_wrapper'
            data-testid='language_selector'
        >
            <Dropdown.Toggle className='custom-toggle'>
                {language}
            </Dropdown.Toggle>
            <Dropdown.Menu
                className='custom-dropdown-menu'
                style={{ minWidth: 'auto' }}
                align="end"
            >
                {languageCodes.map((languageCode, index) => {
                    return <Dropdown.Item
                        className='language_select_items'
                        eventKey={languageCode}
                        key={index}>
                        {languageNames[index]}
                    </Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default LanguageSelector