import { IAttribute } from '../../models/IOutputData'
import './KeyValue.scss'

interface KeyValueProps {
    attributes: IAttribute[]
}

function KeyValue({ attributes }: KeyValueProps) {
    return (
        <>
            {attributes && attributes.length > 0 &&
                attributes?.map((attribute, index) => {
                    return (
                        <div key={index} className="info_pair">
                            <div className="info_key" data-testid='info_key'>
                                {attribute.title}
                            </div>
                            <div className="info_value" data-testid='info_value'>
                                {attribute.value}
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default KeyValue
