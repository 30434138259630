import settingsIcon from '../../assets/settingsIcon.png';
import { GeneralTexts } from '../../language/Texts';
import { IAttribute } from '../../models/IOutputData';
import AccordionComponent from '../accordionComponent/AccordionComponent';
import KeyValue from '../keyValue/KeyValue';
import SectionTitle from '../sectionTitle/SectionTitle';


interface ComponentAtrributesProps {
    attributes: IAttribute[]
}

function ComponentAtrributes({ attributes }: ComponentAtrributesProps) {

    const componentBody = <KeyValue attributes={attributes} />

    const titleComponent =
        <SectionTitle
            icon={<img className="section_title_icon" src={settingsIcon} />}
            title={GeneralTexts.componentAttributes} />

    return (
        <>
            {attributes.length > 0 &&
                <AccordionComponent
                    title={titleComponent}
                    body={componentBody}
                    eventKey="-1"
                />}
        </>
    )
}

export default ComponentAtrributes
