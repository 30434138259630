import rembeLogo from '../../assets/rembeLogo.png'
import { OtherTexts } from '../../language/Texts'
import LanguageSelector from '../languageSelector/LanguageSelector'
import './Header.scss'

interface MainProps {
    language: string,
    setLanguage: (lang: string) => void
}


function Header({ language, setLanguage }: MainProps) {
    return (
        <div className='header_wrapper'>
            <a href={OtherTexts.rembeWebsite} target='_blank'>
                <img
                    className='manufacturer_text_logo'
                    src={rembeLogo}
                    alt="rembe_logo" />
            </a>
            <LanguageSelector
                language={language}
                setLanguage={setLanguage}
            />

        </div>
    )
}

export default Header
