import translation from '../language/translation.json';
import moment from "moment";
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pt';
import i18n from "../language/i18n";

export class Utils {

    static getBrowserLanguage(): string {
        return navigator.language.substring(0, 2)
    }

    static isLanguageSupported(lang: string): boolean {
        return translation.language[lang]
    }

    static isValidUrl(urlString: string): boolean {
        try {
            return Boolean(new URL(urlString));
        }
        catch (e) {
            return false;
        }
    }

    static tryParseDate(value: string) {
        moment.locale(i18n.language)
        const date = moment(value, "YYYY-MM-DD", true)
        return date.format("Do MMM YYYY")
    }
}