import drawerIcon from '../../assets/drawerIcon.png';
import { GeneralTexts } from "../../language/Texts";
import { IAttribute } from '../../models/IOutputData';
import AccordionComponent from '../accordionComponent/AccordionComponent';
import KeyValue from "../keyValue/KeyValue";
import SectionTitle from '../sectionTitle/SectionTitle';

interface ProductAttributesProps {
  attributes: IAttribute[]
}

function ProductAttributes({ attributes }: ProductAttributesProps) {

  const componentBody = <KeyValue attributes={attributes} />

  const titleComponent =
    <SectionTitle
      icon={<img className="section_title_icon" src={drawerIcon} />}
      title={GeneralTexts.productAttributes} />

  return (
    <>
      {attributes.length > 0 &&
        <AccordionComponent
          title={titleComponent}
          body={componentBody}
          eventKey="-1"
        />}
    </>
  )
}

export default ProductAttributes
